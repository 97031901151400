import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../components/custom-snackbar/custom-snackbar.component';

@Injectable()
export class SnackbarService {

  constructor(public snackBar: MatSnackBar) { }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 7000,
      /*verticalPosition: 'top',
      horizontalPosition: 'center'*/
    });
  }

  openBackgroundMsgBar(message: string) {
    this.snackBar.open(message, 'Background Process', {
      duration: 15000
    });
  }

  open(message: string, action: string, time: number) {
    this.snackBar.open(message, action, {
      duration: time
    });
  }


  openSnackBarFromComp(message: string, time: number, status: string) {
    var data1 = [message, status];
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: data1,
      duration: time
    });
  }
}
